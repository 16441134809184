@import "~bootstrap/dist/css/bootstrap.min.css";
body {
  margin: 0;
  font-family: 'Poppins';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas Poppins, 'Courier New',
    monospace;
}
